import { useState, useEffect } from 'react';

import { getBVLoadingStatus, adjustToDynamicCatalog } from './utils';
import {
  BV_READY_EVENT,
  CATALOG_UPDATE_BV_PIXEL_EVENT,
  BV_PIXEL_EVENT_PRODUCT_TYPE,
} from './constants';

const useBVDynamicCatalog = (
  product: ProductTypes.IProduct,
  lang: string,
  brandName: string
): void => {
  const [isBVReady, setBVReady] = useState(getBVLoadingStatus);

  useEffect(() => {
    if (isBVReady) return;

    const bvStatusHandler = () => setBVReady(true);
    document.addEventListener(BV_READY_EVENT, bvStatusHandler);

    return () => {
      document.removeEventListener(BV_READY_EVENT, bvStatusHandler);
    };
  }, []);

  useEffect(() => {
    if (!isBVReady) return;

    const dynamicCatalog = adjustToDynamicCatalog({ product, lang, brandName });

    window.bvDCC = dynamicCatalog;

    window.bvCallback = function (BV) {
      BV.pixel.trackEvent(`${CATALOG_UPDATE_BV_PIXEL_EVENT}`, {
        type: `${BV_PIXEL_EVENT_PRODUCT_TYPE}`,
        locale: window.bvDCC?.catalogData?.locale,
        catalogProducts: window.bvDCC?.catalogData?.catalogProducts,
      });
    };
  }, [isBVReady]);
};

export default useBVDynamicCatalog;
