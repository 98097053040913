import { formatLang } from 'utils/parseHelpers';
import { BVDynamicCatalogCollection, IDynamicCatalogArgs } from './model';

const adjustToDynamicCatalog = ({
  product,
  lang,
  brandName,
}: IDynamicCatalogArgs): BVDynamicCatalogCollection => {
  const { ean, sku, name, descriptionShort, localHeroImage, tags, link } = product;
  const { origin } = window.location;

  const catalogData = {
    catalogData: {
      locale: formatLang(lang),
      catalogProducts: [
        {
          eans: [ean as string],
          productId: ean || sku,
          productName: name,
          brandName,
          productDescription: descriptionShort,
          productImageURL: (origin + localHeroImage?.childImageSharp?.fluid?.src) as string,
          productPageURL: origin + link,
          categoryPath: tags?.map((tag) => ({ id: tag.id, Name: tag.name })),
          inactive: false,
        },
      ],
    },
  };

  return catalogData;
};

const getBVLoadingStatus = () => typeof window !== 'undefined' && !!window.BV;

export { getBVLoadingStatus, adjustToDynamicCatalog };
